import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import sent from "./sent.png";

const Result = () => {
  return (
    <>
      <div
        className="box"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* //display: "block", margin: "0 auto"  */}
        <img
          style={{ width: "200px", paddingTop: "20px" }}
          className="sent"
          src={sent}
          alt="Logo"
        />
        <div
          style={{
            color: "white",
            fontSize: "1rem",
            paddingTop: "20px",
            paddingBottom: "10px",
            textAlign: "center",
            margin: "0 1.2rem",
            fontFamily: "raleway",
          }}
          // className="breve"
        >
          Agradecemos pelo compromisso com seu crescimento pessoal. Logo
          estaremos respondendo você!
        </div>
      </div>
    </>
  );
};

function ContactThree() {
  const [result, setResult] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_2j2k1g6",
        "template_3j10z5q",
        {
          from_name: name,
          message: message,
          reply_to: email,
        },
        "XsAd_rNrfd6VcOQRX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };

  return !result ? (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            {/* <div className="section-title text-left mb--50">
              <h2 className="title">{this.props.contactTitle}</h2>
            </div> */}
            <div className="form-wrapper">
              <form action="" onSubmit={sendEmail}>
                <label htmlFor="item01">
                  <input
                    type="text"
                    name="name"
                    id="item01"
                    // value={this.state.rnName}
                    // onChange={(e) => {
                    //   this.setState({ rnName: e.target.value });
                    // }}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Seu Nome *"
                  />
                </label>

                <label htmlFor="item02">
                  <input
                    type="text"
                    name="email"
                    id="item02"
                    // value={this.state.rnEmail}
                    // onChange={(e) => {
                    //   this.setState({ rnEmail: e.target.value });
                    // }}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Seu E-mail *"
                  />
                </label>
                <label htmlFor="item04">
                  <textarea
                    type="text"
                    id="item04"
                    name="message"
                    // value={this.state.rnMessage}
                    // onChange={(e) => {
                    //   this.setState({ rnMessage: e.target.value });
                    // }}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Sua Mensagem"
                  />
                </label>
                <button
                  className="rn-button-style--2 btn-solid"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              {/* <img src={`${this.props.contactImages}`} alt="trydo"/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Result />
  );
}

export default ContactThree;
